import React from "react";
import { Header } from "../Header";
import { Footer } from "../Footer";

export const RefundPolicy = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto px-4 py-12">
        <h1 className="text-3xl font-bold mb-6">Refund Policy</h1>
        <div className="prose">
          <h2>1. Job Posting Refunds</h2>
          <p>Our refund policy for job postings is as follows:</p>
          <ul>
            <li>Full refund available within 24 hours of purchase if the job posting hasn't been published</li>
            <li>No refunds after job posting goes live</li>
            <li>Partial refunds may be considered in exceptional circumstances</li>
          </ul>

          <h2>2. Eligibility for Refunds</h2>
          <p>Refunds may be considered in these situations:</p>
          <ul>
            <li>Technical issues preventing job posting publication</li>
            <li>Double charging or payment errors</li>
            <li>Service unavailability during the posting period</li>
            <li>Company-wide hiring freeze (with documentation)</li>
          </ul>

          <h2>3. Technical Issues</h2>
          <p>If you experience technical issues:</p>
          <ul>
            <li>Contact support immediately at aimljobhub@gmail.com</li>
            <li>Provide detailed description of the issue</li>
            <li>Include screenshots or error messages if possible</li>
            <li>Our team will respond within 24 business hours</li>
          </ul>

          <h2>4. Refund Process</h2>
          <p>When a refund is approved:</p>
          <ul>
            <li>Refunds are processed through the original payment method</li>
            <li>Processing time: 5-10 business days</li>
            <li>Confirmation email sent when refund is initiated</li>
            <li>Bank processing times may vary</li>
          </ul>

          <h2>5. Non-Refundable Items</h2>
          <p>The following are not eligible for refunds:</p>
          <ul>
            <li>Active job postings</li>
            <li>Premium placement upgrades</li>
            <li>Featured listing add-ons</li>
            <li>Promotional package deals</li>
          </ul>

          <h2>6. Dispute Resolution</h2>
          <p>If you disagree with a refund decision:</p>
          <ul>
            <li>Submit a formal appeal within 5 business days</li>
            <li>Provide additional documentation if requested</li>
            <li>Appeals reviewed within 3 business days</li>
          </ul>

          <h2>7. Contact Information</h2>
          <p>For refund requests and inquiries:</p>
          <ul>
            <li>Email: aimljobhub@gmail.com</li>
            <li>Include order number in all communications</li>
            <li>Response time: 24-48 business hours</li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}; 