import React from "react";
import { Header } from "../Header";
import { Footer } from "../Footer";

export const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className="max-w-4xl mx-auto px-4 py-12">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
        <div className="prose">
          <h2>1. Information We Collect</h2>
          <p>We collect the following types of information:</p>
          <h3>1.1 Information You Provide</h3>
          <ul>
            <li>Contact information (name, email, phone number)</li>
            <li>Company details for job posters</li>
            <li>Payment information</li>
            <li>Job posting content</li>
            <li>Newsletter subscription preferences</li>
          </ul>

          <h3>1.2 Automatically Collected Information</h3>
          <ul>
            <li>IP address and device information</li>
            <li>Browser type and settings</li>
            <li>Usage data and browsing patterns</li>
            <li>Cookies and similar technologies</li>
          </ul>

          <h2>2. How We Use Your Information</h2>
          <p>We use collected information to:</p>
          <ul>
            <li>Provide and improve our job board services</li>
            <li>Process payments and maintain accounts</li>
            <li>Send relevant notifications and updates</li>
            <li>Analyze website performance and user behavior</li>
            <li>Prevent fraud and ensure platform security</li>
            <li>Comply with legal obligations</li>
          </ul>

          <h2>3. Information Sharing</h2>
          <p>We may share your information with:</p>
          <ul>
            <li>Service providers who assist in platform operations</li>
            <li>Payment processors for secure transactions</li>
            <li>Analytics providers to improve our services</li>
            <li>Law enforcement when required by law</li>
          </ul>

          <h2>4. Data Security</h2>
          <p>We implement various security measures including:</p>
          <ul>
            <li>Encryption of sensitive data</li>
            <li>Regular security assessments</li>
            <li>Secure data storage practices</li>
            <li>Limited employee access to personal data</li>
          </ul>

          <h2>5. Your Rights</h2>
          <p>You have the right to:</p>
          <ul>
            <li>Access your personal data</li>
            <li>Request data correction or deletion</li>
            <li>Opt-out of marketing communications</li>
            <li>Request data portability</li>
          </ul>

          <h2>6. Cookies Policy</h2>
          <p>We use cookies to:</p>
          <ul>
            <li>Remember user preferences</li>
            <li>Analyze site traffic and usage</li>
            <li>Improve user experience</li>
            <li>Enable certain website functionalities</li>
          </ul>

          <h2>7. Contact Us</h2>
          <p>For privacy-related inquiries, please contact: aimljobhub@gmail.com</p>
        </div>
      </div>
      <Footer />
    </>
  );
}; 